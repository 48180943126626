/* You can add global styles to this file, and also import other style files */

.full-screen-modal {
	mat-dialog-container {
		padding: 0;
	}
}

.inlineEdit {
	input, textarea {
		padding: 1em 10px 1em 10px;
		word-wrap: break-word;
		background-color: #fff;
		background-clip: border-box;
		border: 1px solid rgba(0, 0, 0, 0.125);
		border-radius: 0.25rem;	
	}

	a {
		cursor: pointer;
		font-size: 14px;
	}
}

#cvBody {
	&.isPreview {
		.d-block.hideInPreview, .hideInPreview, .entry-length0 {
			display: none !important;
		}

	}

	&.noPreview {
		ul[dnd-list] {
			min-height: 42px;
			padding-left: 0px;
		}
	
		ul[dnd-list] .dndDraggingSource {
			display: none;
		}
	
		ul[dnd-list] .dndPlaceholder {
			background-color: #ddd;
			display: block;
			min-height: 42px;
		}
	
		ul[dnd-list] li {
			position: relative;

			.cv_entry {
				display: block;
				border: 1px solid rgba(0, 0, 0, 0); 
				border-radius: 10px;
				padding: 10px;
				position: relative;
				margin-left: 32px;	
			}

			.cvEntryMoveBtn {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: -12px;
				padding: 20px;
				display: none;
			}

			&:hover {
				.cv_entry {
					border: 1px solid rgba(0, 0, 0, 0.125); 
				}

				.cvEntryMoveBtn {
					display: block;
				}
			}

			&.dndDragging {
				border: 1px solid rgba(0, 0, 0, 0); 
			}
		}

		.alert-info.d-none {
			display: block !important;
		}
	}

	.cvEntryEditBtn {
		float: right;
		margin-left: 10px;
		margin-bottom: 10px;
	}

	.cv_entry {
		.cvEntryDocumentNum {
			position: absolute;
			display: inline-block;
			right: -37px;
			top: 4px;

			span{
				position: absolute;
				top: 2px;
				right: 3px;
				display: block;
				min-width: 16px;
				height: 16px;
				background: white;
				color: #0d6efd;
				text-align: center;
				border-radius: 50%;
				font-size: 10px;
				border: 1px solid #0d6efd;
			}
		}
		
		.cvEntryEditBtn, .cvEntryDocumentNum {
			opacity: 0;
		}

		&:hover {
			.cvEntryEditBtn, .cvEntryDocumentNum {
				opacity: 1;
			}
		}
	}
}

.previewItem {
  overflow: hidden;
  position: absolute;
  transform: scale(0.2);
  top: -385px;
	left: -283px;
	width: 760px;
	height: 990px;
  user-select: none;

  .hideInPreview, .entry-length0 {
    display: none !important;
  }
}

.modal.documentDisplay {
	.modal-dialog {
		max-width: 90% !important;

		table {
			width: 100%;
			border-collapse: collapse;

			th,td {
				padding: 5px;
				border: 1px solid #e9ecef;
				line-height: 12px;
				font-size: 14px;
			}
		}
	}
}
